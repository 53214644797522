import * as Yup from "yup";
import { toInternationalFormat } from "../../../../utils";

export const createCampaignObject = {
  brandID: "",
  audienceID: "",
  campaignID: "",
  startTime: "",
  previewImage: "",
  isEditable: true,
  campaignStatus: "",
  isBroadcast: false,
  audienceSize: {},
  permissionSettings: {},
  brandDetails: {
    brandName: "",
    brandLogo: "",
  },

  campaign_name: {
    value: "",
  },

  about: {
    value: "",
  },

  destination_url: {
    value: "",
  },

  start_duration: {
    value: null,
  },

  end_duration: {
    value: null,
  },

  volume: {
    value: "",
  },

  budget: {
    value: {
      min: "",
      max: "",
    },
  },

  monthly_active_user: {
    value: "",
  },

  conversion_type: {
    value: "",
  },

  payout_percentage: {
    value: "",
  },

  payout_terms: {
    value: "",
  },

  trial_period: {
    value: "",
  },

  category: {
    value: [],
  },

  creatives: [],

  pref_campaign_channel: {
    value: [],
  },

  whatsapp_template: {
    value: "",
  },

  whatsapp_agent: {
    value: "",
  },

  agentId: {
    value: [],
  },
};

export function objectFromFormData(formData, activeBrand) {
  let sDate = new Date(formData.start_duration.value);
  let eDate = new Date(formData.end_duration.value);

  let prefCampaignChannel = formData.pref_campaign_channel?.value?.filter(
    (item) => item !== null && item !== undefined
  );

  let fields = [
    {
      campaign_name: formData.campaign_name,
    },

    {
      about: formData.about,
    },

    {
      destination_url: formData.destination_url,
    },

    {
      start_duration: {
        value: sDate.toISOString(),
      },
    },

    {
      end_duration: { value: eDate.toISOString() },
    },

    {
      volume: {
        value: parseInt(formData.volume?.value),
      },
    },

    {
      min_budget: {
        value: parseInt(formData.budget.value.min),
      },
    },

    {
      max_budget: {
        value: parseInt(formData.budget.value.max),
      },
    },

    {
      monthly_active_user: {
        value: parseInt(formData.monthly_active_user?.value),
      },
    },

    {
      category: formData.category,
    },

    {
      conversion_type: formData.conversion_type,
    },

    {
      payout_percentage: formData.payout_percentage,
    },

    {
      payout_terms: formData.payout_terms,
    },

    {
      trial_period: formData.trial_period,
    },

    {
      pref_campaign_channel: { value: prefCampaignChannel },
    },

    {
      whatsapp_template: formData.whatsapp_template,
    },

    {
      whatsapp_agent: formData.whatsapp_agent,
    },
  ];

  let data = new FormData();

  data.append("brand_id", parseInt(activeBrand.ID));
  data.append("audience_id", formData.audienceID);
  data.append("previewImage", formData.previewImage);
  data.append("fields", JSON.stringify(fields));
  return data;
}

export function creative(formData, campaignID) {
  return {
    creative:
      formData.sourceType === "link" ? formData.fileUrl : formData.creative,
    campaign_id: campaignID,
    adtype_id: formData.adSize,
    format: formData.format,
    link: formData.fileUrl,
    source_type: formData.sourceType,
    creative_name: formData.friendlyName,
    ...Boolean(formData.format === "ad-tag" && { ad_tag: formData.adTag }),
  };
}

export function objectFromResponse(response) {
  let data = {};
  let creativesData = [];
  for (let key of response.campaignData) {
    if (Object.keys(key)[0] === "category") {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0] || [],
      };
    }

    if (
      Object.keys(key)[0] === "monthly_active_user" ||
      Object.keys(key)[0] === "volume"
    ) {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0] || "",
      };
    } else if (Object.keys(key)[0] === "min_budget") {
      data["budget"] = {
        value: {
          min: Object.values(key)[0] || "",
        },
      };
    } else if (Object.keys(key)[0] === "max_budget") {
      data["budget"] = {
        value: {
          min: data.budget.value.min,
          max: Object.values(key)[0] || "",
        },
      };
    } else {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0] || "",
      };
    }
  }

  for (let create of response.creatives) {
    creativesData.push({
      creativeID: create.creative_id,
      format: create.format,
      creative: create.creative,
      friendlyName: create.creative_name,
      adSize: create.adtype_id,
      adTag: create.ad_tag || "",
      sourceType: create.source_type,
      fileUrl: create.creative,
    });
  }

  return {
    ...data,
    permissionSettings: response.admin_settings,
    audienceSize: response.audienceSize,
    creatives: creativesData,
    brandID: response.brand_id,
    fileName: response.fileName,
    adminCampaignStatus: response.admin_campaign_status,
    campaignStatus: response.campaignStatus,
    campaignID: response.campaign_id,
    isEditable: response.is_editable,
    previewImage: response.preview_image,
    audienceID: response.audience.audience_id,
    campaignAdminStatus: response.admin_campaign_status,
    campaignLaunched: response.campaign_launched,
    csvUpload: response?.csv_upload,
    isBroadcast: response.isBroadcast,
    creditDetails: response.credit_details,
    status: response?.status,
    Intersection_Audience: response?.Intersection_Audience,
    cpm: response?.cpm,
  };
}

export function getAllCampaigns(response, activeBrand) {
  let allCampaigns = [];
  for (let key of response.data.campaignData) {
    let data = {};
    for (let fields of key.fields) {
      if (
        Object.keys(fields)[0] === "budget_min" ||
        Object.keys(fields)[0] === "budget_max" ||
        Object.keys(fields)[0] === "monthly_active_user" ||
        Object.keys(fields)[0] === "volume"
      ) {
        data[Object.keys(fields)[0]] = toInternationalFormat(
          Object.values(fields)[0]
        );
      } else {
        data[Object.keys(fields)[0]] = Object.values(fields)[0];
      }
    }
    allCampaigns.push({
      ...data,
      brand: {
        name: activeBrand.brandName,
        logo: activeBrand.logo,
      },
      previewImage: !!key.preview_image
        ? key.preview_image
        : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
      status: key.status,
      audience_id: key.audience_id,
      brand_id: key.brand_id,
      campaign_id: key.campaign_id,
      user_Count: key?.total_user_count,
      audience_size: key?.audience_size,
    });
  }
  return allCampaigns;
}

export function getSuperAdminCampaigns(response) {
  let allCampaigns = [];
  for (let key of response.data.formattedCampaigns) {
    let data = {};
    for (let fields of key.fields) {
      if (
        Object.keys(fields)[0] === "budget_min" ||
        Object.keys(fields)[0] === "budget_max" ||
        Object.keys(fields)[0] === "monthly_active_user" ||
        Object.keys(fields)[0] === "volume"
      ) {
        data[Object.keys(fields)[0]] = toInternationalFormat(
          Object.values(fields)[0]
        );
      } else {
        data[Object.keys(fields)[0]] = Object.values(fields)[0];
      }
    }
    allCampaigns.push({
      ...data,
      brand: {
        name: key.brand.brand_name,
        logo: key.brand.brand_logo,
        agency: key?.brand?.agency_name,
      },
      previewImage: !!key.preview_image
        ? key.preview_image
        : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
      status: key.status,
      audience_id: key.audience_id,
      brand_id: key.brand_id,
      campaign_id: key.campaign_id,
      user_Count: key.user_Count,
    });
  }
  return allCampaigns;
}

export const validationSchema = Yup.object().shape({
  campaign_name: Yup.object().shape({
    value: Yup.string().required("Campaign Name is required").min(3),
  }),

  audienceID: Yup.string().required("Audience is required"),

  previewImage: Yup.string().required("Preview Image is required"),

  volume: Yup.object().shape({
    value: Yup.string()
      .matches(/^(([0-9](,)?)*)+$/, "Desired Volume should be number")
      .required("Volume is required"),
  }),

  start_duration: Yup.object().shape({
    value: Yup.date("It should be date")
      .required("Start Date is required")
      .typeError("Start Date is required"),
  }),

  end_duration: Yup.object().shape({
    value: Yup.date()
      .test(
        "same_dates_test",
        "End date should be greater than start date.",
        function (value) {
          const start_duration = this.from[1]?.value?.start_duration.value;
          return new Date(value).getTime() > new Date(start_duration).getTime();
        }
      )
      .required("End Date is required")
      .typeError("End Date is required"),
  }),

  monthly_active_user: Yup.object().shape({
    value: Yup.string().matches(
      /^(([0-9](,)?)*)+$/,
      "Monthly Active Users should be number"
    ),
  }),

  budget: Yup.object().shape({
    value: Yup.object().shape({
      min: Yup.number("It should be a number")
        .positive("Budget should be positive")
        .nullable()
        // .lessThan(Yup.ref("max"), "Max Budget should be greater")
        .required("Minimum budget is required")
        .typeError("Minimum budget should be a number"),

      max: Yup.number()
        .nullable()
        .positive("Budget should be positive")
        .moreThan(Yup.ref("min"), "Max Budget should be greater")
        .typeError("Maximum budget should be a number")
        .required("Maximum budget is required"),
    }),
  }),

  destination_url: Yup.object().shape({
    value: Yup.string()
      .matches(
        /^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
        "Enter correct url!"
      )
      .required("Destination url is required"),
  }),

  conversion_type: Yup.object().shape({
    value: Yup.string().required("Conversion Type is required"),
  }),

  pref_campaign_channel: Yup.object().shape({
    value: Yup.array()
      .required("Preferred Campaign Channel is required")
      .min(1, "Atleast one campaign channel should be specified"),
  }),

  whatsapp_template: Yup.object().when("pref_campaign_channel.value", {
    is: (value) => value.length > 0 && value.includes(1),
    then: () =>
      Yup.object().shape({
        value: Yup.string().required("WhatsApp Template is required"),
      }),
    otherwise: () =>
      Yup.object().shape({
        value: Yup.string(),
      }),
  }),

  whatsapp_agent: Yup.object().when("pref_campaign_channel.value", {
    is: (value) => value.length > 0 && value.includes(1),
    then: () =>
      Yup.object().shape({
        value: Yup.string().required("WhatsApp Agent is required"),
      }),
    otherwise: () =>
      Yup.object().shape({
        value: Yup.string(),
      }),
  }),
});

export function getAudienceDetails(audienceData) {
  let data = {};
  let additionalFields = [];

  if (!!audienceData.fields) {
    for (let key of audienceData?.fields) {
      if (
        (typeof Object.values(key)[0] === "string" &&
          !!Object.values(key)[0]) ||
        (Array.isArray(Object.values(key)[0]) &&
          Object.values(key)[0].length > 0) ||
        (!Array.isArray(Object.values(key)[0]) &&
          typeof Object.values(key)[0] === "object" &&
          Object.keys(Object.values(key)[0]).length > 0 &&
          !!Object.values(key)[0][Object.keys(Object.values(key)[0])[0]] &&
          !!Object.values(key)[0][Object.keys(Object.values(key)[0])[1]]) ||
        (typeof Object.values(key)[0] === "number" && !!Object.values(key)[0])
      ) {
        if (!key?.is_additional) {
          data[Object.keys(key)[0]] = {
            value: Object.values(key)[0],
            mandatory: Object.values(key)[1],
          };
        } else {
          additionalFields.push({
            key: Object.keys(key)[0],
            value: Object.values(key)[0],
            mandatory: Object.values(key)[1],
          });
        }
      }
    }
  } else {
    for (let key of audienceData) {
      if (
        (typeof Object.values(key)[0] === "string" &&
          !!Object.values(key)[0]) ||
        (Array.isArray(Object.values(key)[0]) &&
          Object.values(key)[0].length > 0) ||
        (!Array.isArray(Object.values(key)[0]) &&
          typeof Object.values(key)[0] === "object" &&
          Object.keys(Object.values(key)[0]).length > 0 &&
          !!Object.values(key)[0][Object.keys(Object.values(key)[0])[0]] &&
          !!Object.values(key)[0][Object.keys(Object.values(key)[0])[1]]) ||
        (typeof Object.values(key)[0] === "number" && !!Object.values(key)[0])
      ) {
        if (!key?.is_additional) {
          data[Object.keys(key)[0]] = {
            value: Object.values(key)[0],
            mandatory: Object.values(key)[1],
          };
        } else {
          additionalFields.push({
            key: Object.keys(key)[0],
            value: Object.values(key)[0],
            mandatory: Object.values(key)[1],
          });
        }
      }
    }
  }

  data = {
    ...data,
    isEditable: audienceData.is_editable,
    additionalFields: additionalFields,
    ID: audienceData.audience_id,
    brandID: audienceData.brand_id,
  };

  return data;
}

export function connectionLists(connections) {
  let connection = [];
  for (let con of connections) {
    connection.push({
      requestStatus: con.requestStatus.replace(/_/g, " "),
      logo: con.receiver_brand_info?.logo,
      monthly_active_user:
        toInternationalFormat(con.receiver_brand_info?.mau) || "",
      brandId: con.receiver_brand_info?.brandId,
      category: con.receiver_brand_info?.category || [],
      languages: con.receiver_brand_info?.languages || [],
      locations: con.receiver_brand_info?.location || [],
      name: con.receiver_brand_info?.brand_name || "",
      usersCount: con?.users_count || 0,
      senderUserId: con.sender_userId,
      senderBrandId: con.sender_brandId,
      senderCampaignId: con.sender_campaignId,
      receiverUserId: con.receiver_userId,
      receiverBrandId: con.receiver_brandId,
    });
  }
  return connection;
}
