/* Routes for sidebar menu
  For a single entry: {
    name: Entry Display Name,
    icon: Entry Icon,
    ID: dashboardRoutes.[route name].ID,
    path: dashboardRoutes.[route name].path,
  }

  For a folder: {
    name: Folder Display Name,
    icon: Folder Icon,
    ID: random ID for key for component in the loop,
    children: [{
      name: Sub entry name,
      path: dashboardRoutes.[route name].path,
    }],
  }
*/
import { MdCampaign } from "react-icons/md";
import { HiTemplate } from "react-icons/hi";
import { IoSettings } from "react-icons/io5";
import { FaUserGroup } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa6";
import { HiUserGroup } from "react-icons/hi2";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaHandshakeSimple } from "react-icons/fa6";
import { BiSolidUserAccount } from "react-icons/bi";
import { MdOutlineBrandingWatermark } from "react-icons/md";

import dashboardLayoutRoutes from "../../../router/router components/dashboardLayoutRoutes";
const routes = [
  {
    name: "Profile",
    icon: FaUserGroup,
    ID: dashboardLayoutRoutes.brandDetailsRoute.ID,
    children: [
      {
        ID: dashboardLayoutRoutes.agencyDetailsRoute.ID,
        name: "Agency",
        path: dashboardLayoutRoutes.agencyDetailsRoute.path,
      },
      {
        ID: dashboardLayoutRoutes.brandDetailsRoute.ID,
        name: "Brand",
        path: dashboardLayoutRoutes.brandDetailsRoute.path,
      },
      {
        ID: dashboardLayoutRoutes.companyDetailsRoute.ID,
        name: "Company",
        path:
          dashboardLayoutRoutes.companyDetailsRoute.path +
          "?withCompany=" +
          true,
      },
    ],
  },

  {
    name: "Audience",
    icon: HiUserGroup,
    ID: dashboardLayoutRoutes.audienceListRoute.ID,
    path: dashboardLayoutRoutes.audienceListRoute.path,
  },

  // {
  //   name: "Templates",
  //   icon: HiTemplate,
  //   ID: dashboardLayoutRoutes.templateListRoute.ID,
  //   path: dashboardLayoutRoutes.templateListRoute.path,
  // },

  // {
  //   name: "Credits",
  //   icon: FaCoins,
  //   ID: dashboardLayoutRoutes.creditRoute.ID,
  //   path: dashboardLayoutRoutes.creditRoute.path,
  // },

  {
    name: "Accounts",
    icon: BiSolidUserAccount,
    ID: dashboardLayoutRoutes.accountVerificationRoute.ID,
    path: dashboardLayoutRoutes.accountVerificationRoute.path,
  },

  {
    name: "Verification",
    icon: MdOutlineBrandingWatermark,
    ID: dashboardLayoutRoutes.unverifiedRoute.ID,
    children: [
      {
        name: "Brands",
        ID: dashboardLayoutRoutes.unverifiedRoute.ID,
        path: dashboardLayoutRoutes.unverifiedRoute.path,
      },
      {
        name: "Agencies",
        ID: dashboardLayoutRoutes.unverifiedAgenciesRoute.ID,
        path: dashboardLayoutRoutes.unverifiedAgenciesRoute.path,
      },
    ],
  },

  {
    name: "Campaigns",
    icon: MdCampaign,
    ID: dashboardLayoutRoutes.superadminAllCampaignsRoute.ID,
    path: dashboardLayoutRoutes.superadminAllCampaignsRoute.path,
  },

  {
    name: "Campaigns",
    icon: MdCampaign,
    ID: dashboardLayoutRoutes.myCampaignRoute.ID,
    path: dashboardLayoutRoutes.myCampaignRoute.path,
  },

  {
    name: "Campaigns",
    icon: MdCampaign,
    ID: dashboardLayoutRoutes.campaignRequestRoute.ID,
    path: dashboardLayoutRoutes.campaignRequestRoute.path,
  },

  {
    name: "Users",
    icon: FaUserGroup,
    ID: dashboardLayoutRoutes.brandListRoute.ID,
    path: dashboardLayoutRoutes.brandListRoute.path,
  },

  {
    name: "On-Boarding",
    icon: FaHandshakeSimple,
    ID: dashboardLayoutRoutes.superadminDPOnBoardingListRoute.ID,
    path: dashboardLayoutRoutes.superadminDPOnBoardingListRoute.path,
  },

  // {
  //   name: "WhatsApp Onboarding",
  //   icon: IoLogoWhatsapp,
  //   ID: dashboardLayoutRoutes.whatsAppOnboardingPageRoute.ID,
  //   path: dashboardLayoutRoutes.whatsAppOnboardingPageRoute.path,
  // },

  {
    name: "Settings",
    icon: IoSettings,
    ID: dashboardLayoutRoutes.superadminPermissionSettingsRoute.ID,
    children: [
      {
        ID: dashboardLayoutRoutes.superadminPermissionSettingsRoute.ID,
        name: "Permission Settings",
        path: dashboardLayoutRoutes.superadminPermissionSettingsRoute.path,
      },
      {
        ID: dashboardLayoutRoutes.superadminDynamicUISetting.ID,
        name: "Site Settings",
        path: dashboardLayoutRoutes.superadminDynamicUISetting.path,
      },
    ],
  },
];

export default routes;
