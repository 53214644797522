import * as Yup from "yup";

export const companyObject = {
  ID: "",
  getAgreement: false,
  signAgreement: false,
  gstVerified: false,
  verifyNumber: false,
  companyName: "",
  companyAddress: "",
  companyPAN: "",
  companyContact: "",
  companyNatureBusiness: "",
  companyGSTNumber: "",
  companyDirectorsName: "",
  companyDirectorsPanNumber: "",
  companyDirectorAadharNumber: "",
  gstCertificate: "",
  companyPanCertificate: "",
  directorPanCertificate: "",
  directorAadharCertificate: "",
  is_brand_verified: false,
  viewAgreement: {},
};

export function objectFromFormData(formData, brandID, agencyID) {
  return {
    ...(!!brandID && { brand_id: parseInt(brandID) }),
    ...(!!agencyID && { agency_id: parseInt(agencyID) }),
    company_name: formData.companyName,
    gst_no: formData.companyGSTNumber,
    contact_no: formData.companyContact,
    company_address: formData.companyAddress,
    pan_no: formData.companyPAN,
    director_name: formData.companyDirectorsName,
    director_pan: formData.companyDirectorsPanNumber,
    director_aadhar: formData.companyDirectorAadharNumber,
    nature_of_business: formData.companyNatureBusiness,
    gst_certificate: formData.gstCertificate,
    company_pan_certificate: formData.companyPanCertificate,
    director_pan_certificate: formData.directorPanCertificate,
    director_aadhar_certificate: formData.directorAadharCertificate,
  };
}

export function objectFromResponseData(responseData) {
  return {
    is_brand_verified: responseData.is_brand_verified,
    is_agency_verified: responseData.is_agency_verified,
    brandID: responseData.brand_id,
    agencyID: responseData.agency_id,
    ID: responseData.id,
    getAgreement: responseData.get_agreement,
    signAgreement: responseData.sign_agreement,
    gstVerified: responseData.gst_verified,
    verifyNumber: responseData.is_otp_verified,
    companyName: responseData.company_name,
    companyAddress: responseData.address,
    companyPAN: responseData.pan_no,
    companyContact: responseData.contact_no,
    companyNatureBusiness: responseData.nature_of_business,
    companyGSTNumber: responseData.gst,
    companyDirectorsName: responseData.director_name,
    companyDirectorsPanNumber: responseData.director_pan,
    companyDirectorAadharNumber: responseData.director_aadhar,
    gstCertificate: responseData.gst_certificate,
    companyPanCertificate: responseData.company_pan_certificate,
    directorPanCertificate: responseData.director_pan_certificate,
    directorAadharCertificate: responseData.director_aadhar_certificate,
    viewAgreement: responseData.agreement_data,
    mandatoryDetailsSubmitted: responseData.mandatory_details_submitted,
  };
}

export const validationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required"),
  companyAddress: Yup.string().required("Company Address is required"),
  companyContact: Yup.string("Company Contact cant not be empty")
    .matches(/^\d+$/, "It should contain number only")
    .length(10, "Contact number should be of 10 digits")
    .required("Contact Number is required")
    .typeError("Company Contact cant not be empty"),
  companyDirectorsName: Yup.string().required(
    "Company Director's Name is required"
  ),
  // gstCertificate: Yup.mixed().required("GST Certificate is required"),
  companyPanCertificate: Yup.mixed().required(
    "Company PAN Certificate is required"
  ),
  directorPanCertificate: Yup.mixed().required(
    "Company director's PAN Certificate is required"
  ),
  // gstVerified: Yup.boolean().oneOf([true], "GST number must be verify"),
  verifyNumber: Yup.boolean().oneOf([true], "GST number must be verify"),
  // directorAadharCertificate: Yup.mixed().required(
  //   "Company director's Aadhar Certificate is required"
  // ),
});
