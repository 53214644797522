import { Grid } from "@mui/material";
import { titleCase } from "change-case-all";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import EditButtons from "../../../components/EditButtons";
import {
  ButtonFooter,
  PageHeader,
  Paper,
  FloatingEditActionButtons,
} from "../../../components";

export default function FormDisplay({
  agencyID,
  multiBrand,
  list,
  editState,
  formikProps,
  setEditState,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Brand Details"}
          pageHeader={
            !formikProps.values.brandID
              ? "Let's add brand information"
              : titleCase(formikProps.values.brandName)
          }
          buttons={[
            <EditButtons editState={editState} setEditState={setEditState} />,
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper gridSpacing={4}>
          <FormOne
            agencyID={agencyID}
            multiBrand={multiBrand}
            formikProps={formikProps}
            editState={editState}
          />

          <FormTwo
            agencyID={agencyID}
            list={list}
            multiBrand={multiBrand}
            formikProps={formikProps}
            editState={editState}
          />

          <FloatingEditActionButtons
            ID={formikProps.values.brandID}
            editState={editState}
            setEditState={setEditState}
            formikProps={formikProps}
          />
        </Paper>

        {editState && (
          <Grid item xs={12}>
            <ButtonFooter
              ID={formikProps.values.brandID}
              editState={editState}
              setEditState={setEditState}
              formikProps={formikProps}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
