import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useComponent } from "../../../hooks";
import { objectFromFormData, validationSchema } from "./onboardingObjects";

export default function Form({
  fieldValue,
  fields,
  brands,
  formData,
  editState,
  setEditState,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { alert } = useComponent();

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/user/onboarding",
          method: "POST",
          data: objectFromFormData(formData, fieldValue),
          disableRedirect: true,
        }).then((response) => {
          if (response.status) {
            navigate(
              pages.dpOnBoardingDetails.route +
                response.data.id +
                "?brandID=" +
                response?.data?.brand_id
            );
            setEditState(false);
            dispatch(alert.success(response?.message?.displayMessage));
          }

          setSubmitting(false);
        });
      }}
    >
      {(formikProps) => (
        <FormDisplay
          fields={fields}
          formikProps={formikProps}
          brands={brands}
          editState={editState}
          setEditState={setEditState}
        />
      )}
    </Formik>
  );
}
