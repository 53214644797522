import { Grid } from "@mui/material";

import FormOne from "./FormOne";
import FieldName from "./FieldName";
import { PageHeader, Paper } from "../../../components";
import { CancelButton } from "../../css components/Button";
import { useNavigate } from "react-router-dom";

export default function FormDisplay({
  brands,
  editState,
  formikProps,
  setEditState,
}) {
  const navigate = useNavigate();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          pageName={"Onboarded Brand Details"}
          pageHeader={formikProps.values.brandDetails?.brand_name}
          buttons={[
            <CancelButton variant="outlined" onClick={() => navigate(-1)}>
              Back
            </CancelButton>,
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Paper gridSpacing={6}>
          <FormOne
            formikProps={formikProps}
            brands={brands}
            editState={editState}
            setEditState={setEditState}
          />

          {formikProps.values.connection && (
            <FieldName formikProps={formikProps} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
}
