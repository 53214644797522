import { set } from "lodash";
import { Fragment } from "react";
import styled from "styled-components/macro";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Grid, Typography, Checkbox } from "@mui/material";
import { ToggleOnOutlined, ToggleOffOutlined } from "@mui/icons-material";

import { TextField, Autocomplete } from "../../../components";
import AutocompleteMultiSelect from "../../../components/MultiselectAutoComplete";

const Heading = styled(Typography)`
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  align-self: center;
`;

const DpCount = styled(Typography)`
  color: #333;
  font-weight: 400;
  font-size: 13px;
  text-align: end;
`;

export default function FormOne({
  count,
  fields,
  editState,
  formikProps,
  mandatoryFalseCount,
  percentageSliderValue,
  oldMandatoryFalseCount,
  setPercentageSliderValue,
  setOldMandatoryFalseCount,
}) {
  function handleCheckChange(e) {
    let { name } = e.target;
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValues(prevVal[objectName]),
      percentage: setPercentage(prevVal.percentage),
    }));

    function setPercentage(percentage) {
      let p = percentage.value;

      if (
        mandatoryFalseCount <= percentageSliderValue &&
        Boolean(formikProps.values.ID)
      ) {
        if (p === percentageSliderValue && p === mandatoryFalseCount) {
          count.current = false;
        }

        if (!e.target.checked) {
          setPercentageSliderValue(mandatoryFalseCount);
          p = mandatoryFalseCount;
        } else {
          p = p - 1;
        }
      }

      if (
        mandatoryFalseCount < oldMandatoryFalseCount &&
        oldMandatoryFalseCount === percentageSliderValue &&
        !Boolean(formikProps.values.ID)
      ) {
        setPercentageSliderValue(mandatoryFalseCount);
        p = mandatoryFalseCount;
      }

      setOldMandatoryFalseCount(mandatoryFalseCount);

      return {
        ...percentage,
        value: p,
      };
    }

    function setValues(val) {
      return {
        ...val,
        mandatory: e.target.checked,
      };
    }
  }

  function handleTextChange(event) {
    const {
      target: { value, name },
    } = event;
    let objectName = name.substring(0, name.indexOf("."));
    const name1 = name.split(".");

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValues(prevVal[objectName]),
      dirty: true,
      // percentage: setPercentage(prevVal.percentage),
    }));

    function setValues(val) {
      if (typeof name[name1] === "string") {
        val.mandatory = true;
        val.value = value;
      } else {
        set(val, name.substring(name.indexOf(".") + 1), value);
        if (!val.value.min) {
          val.mandatory = false;
        }
      }

      return val;
    }
  }

  return (
    <Fragment>
      <Grid item xs={12}>
        <TextField
          label="Audience Group Name"
          formikProps={formikProps}
          name="audience_group.value"
          placeholder="Audience Group Name"
          disabled={!editState}
        />
      </Grid>

      {fields.map(
        (field, index) =>
          index % 2 === 0 && (
            <Fragment>
              {/* textfield */}

              {field.control_id === 3 && (
                <Grid item xs={6}>
                  <Mandatory field={field} />

                  <TextField
                    formikProps={formikProps}
                    label={field.label}
                    name={`${field.name}.value`}
                    placeholder={field.label}
                    disabled={!editState}
                  />

                  <DpCount>
                    {formikProps.values[field.name]?.userCount > 0 &&
                      `${formikProps.values[field.name]?.userCount.toFixed(
                        2
                      )} Million Users`}
                  </DpCount>
                </Grid>
              )}

              {field.control_id === 1 && (
                <Fragment>
                  <Grid item xs={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} style={{ paddingTop: "0px" }}>
                        <Mandatory field={field} />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          onChange={handleTextChange}
                          label={field.options[0].label}
                          formikProps={formikProps}
                          name={`${field.name}.value.${field.options[0]["value"]}`}
                          placeholder={field.options[0].label}
                          disabled={!editState}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <TextField
                          onChange={handleTextChange}
                          label={field.options[1].label}
                          formikProps={formikProps}
                          name={`${field.name}.value.${field.options[1]["value"]}`}
                          placeholder={field.options[1].label}
                          disabled={!editState}
                        />

                        <DpCount>
                          {formikProps.values[field.name]?.userCount > 0 &&
                            `${formikProps.values[
                              field.name
                            ]?.userCount.toFixed(2)} Million Users`}
                        </DpCount>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              )}

              {field.control_id === 2 && field.multi_select && (
                <Grid item xs={6}>
                  <Mandatory field={field} />

                  <AutocompleteMultiSelect
                    forcePopupIcon={!editState ? false : true}
                    label={field.label}
                    name={`${field.name}.value`}
                    formikProps={formikProps}
                    disabled={!editState}
                    defaults={{
                      primaryKey: "value",
                      displayLabel: "label",
                    }}
                    options={field.options}
                    placeholder={field.placeholder}
                    onChange={(_, arr, reason, detail) =>
                      handleChange(
                        arr,
                        detail,
                        reason,
                        `${field.name}.value`,
                        "value",
                        field.options
                      )
                    }
                  />

                  <DpCount
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "5px",
                    }}
                  >
                    {formikProps.values[field.name]?.userCount > 0 &&
                      `${formikProps.values[field.name]?.userCount.toFixed(
                        2
                      )} Million Users`}
                  </DpCount>
                </Grid>
              )}

              {field.control_id === 2 && !field.multi_select && (
                <Grid item xs={6}>
                  <Mandatory field={field} />

                  <Autocomplete
                    label={field.label}
                    forcePopupIcon={!editState ? false : true}
                    name={`${field.name}.value`}
                    placeholder={field.placeholder}
                    defaults={{
                      primaryKey: "value",
                      displayLabel: "label",
                    }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        display: !editState && "none",
                      },
                    }}
                    options={field.options}
                    disabled={!editState}
                    onChange={(_, value, type) =>
                      handleChangeSingleSelect(value, type, `${field.name}`)
                    }
                    formikProps={formikProps}
                  />

                  <DpCount
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "5px",
                    }}
                  >
                    {formikProps.values[field.name]?.userCount > 0 &&
                      `${formikProps.values[field.name]?.userCount.toFixed(
                        2
                      )} Million Users`}
                  </DpCount>
                </Grid>
              )}

              {field.control_id === 4 && (
                <Grid item xs={6}>
                  <Mandatory field={field} />

                  <DesktopDatePicker
                    label={field.label}
                    name={`${field.name}.value`}
                    formikProps={formikProps}
                    disabled={!editState}
                    value={formikProps.values[field.name]?.value}
                    onChange={(newValue) => {
                      let date = new Date(newValue);

                      if ((date.getFullYear() + "").length < 3) {
                        return formikProps.setValues((prevVal) => ({
                          ...prevVal,
                          [field.name]: {
                            mandatory: false,
                            userCount: 0,
                            value: null,
                          },
                        }));
                      }

                      let d =
                        date.getFullYear() +
                        "-" +
                        "0" +
                        (date.getMonth() + 1) +
                        "-" +
                        date.getDate();

                      formikProps.setValues((prevVal) => ({
                        ...prevVal,
                        [field.name]: {
                          ...prevVal[field.name],
                          value: d,
                        },
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        disabled={!editState}
                        name={`${field.name}.value`}
                        formikProps={formikProps}
                        {...params}
                      />
                    )}
                  />
                </Grid>
              )}
            </Fragment>
          )
      )}
    </Fragment>
  );

  function Mandatory({ field }) {
    return (
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Heading
          style={{
            color: formikProps.values[field.name]?.mandatory ? "red" : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          style={{ padding: "3px 9px" }}
          disabled={
            field.control_id === 1
              ? !formikProps.values[field.name]?.value.min ||
                !formikProps.values[field.name]?.value.max ||
                !editState
              : !editState ||
                !formikProps.values[field.name]?.value ||
                formikProps.values[field.name]?.value.length === 0
          }
          name={`${field.name}.mandatory`}
          onChange={handleCheckChange}
          checked={formikProps.values[field.name]?.mandatory}
          icon={<ToggleOffOutlined fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlined fontSize="large" style={{ color: "red" }} />
          }
        />
      </div>
    );
  }

  function handleChangeSingleSelect(value, type, name) {
    function setValue(val) {
      val = {
        ...val,
        value: value.value,
      };

      return val;
    }
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        [name]: setValue(prevVal[name]),
      }));
    } else {
      value = {
        value: "",
        label: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        [name]: setValue(prevVal[name]),
      }));
    }
  }

  function handleChange(arr, { option }, reason, name, displayName, options) {
    let objectName = name.substring(0, name.indexOf("."));
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValuesOfDropDown(prevVal[objectName]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val.value];
      let count = val.userCount;
      let mandatory = val.mandatory;

      if (reason === "removeOption") {
        if (option.value) {
          values = [...values.filter((items) => items !== option?.value)];
        } else {
          values?.pop();
          option = options.filter((item) => item?.label === option)[0];
        }

        count = count - option?.count;
      }
      if (reason === "selectOption") {
        values.push(option[displayName]);
        count = count + option.count;
      }

      if (values.length === 0) {
        mandatory = false;
      }
      return {
        ...val,
        value: values,
        userCount: count,
        mandatory: mandatory,
      };
    }
  }
}
