import * as Yup from "yup";

export const agencyObject = {
  agencyID: "",
  businessEntityId: "",
  agencyName: "",
  website: "",
  socialMedia: {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  },
  avatar: "",
  about: "",
};

export function objectFromFormData(formData, businessEntityID) {
  let smh = {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  };

  let fb = formData.socialMedia?.facebook?.lastIndexOf("/");
  let ig = formData.socialMedia?.instagram?.lastIndexOf("/");
  let tw = formData.socialMedia?.twitter?.lastIndexOf("/");
  let wh = formData.socialMedia?.whatsapp?.lastIndexOf("/");

  smh.facebook = formData.socialMedia?.facebook?.substring(fb + 1);
  smh.instagram = formData.socialMedia?.instagram?.substring(ig + 1);
  smh.twitter = formData.socialMedia?.twitter?.substring(tw + 1);
  smh.whatsapp = formData.socialMedia?.whatsapp?.substring(wh + 1);

  return {
    business_entity_id: parseInt(businessEntityID),
    agency_name: formData.agencyName,
    website: formData.website,
    about: formData.about,
    agencyLogo: formData.avatar,
    social_media_handles: smh,
  };
}

export function objectFromResponse(response) {
  let medias = response?.social_media_handles;
  let fb = medias.facebook?.replace("@", "");
  let ig = medias.instagram?.replace("@", "");
  let tw = medias.twitter?.replace("@", "");

  return {
    agencyID: response.agency_id,
    businessEntityId: response.business_entity_id,
    agencyName: response.agency_name,
    website: response.website,
    socialMedia: {
      facebook: fb && "facebook.com/" + fb,
      instagram: ig && "instagram.com/" + ig,
      twitter: tw && "twitter.com/" + tw,
      whatsapp: medias.whatsapp,
    },
    avatar: response.logo,
    about: response.about,
  };
}

export const validationSchema = Yup.object().shape({
  agencyName: Yup.string().required("Agency name is required"),
  website: Yup.string().required("Website is required"),
  avatar: Yup.string().required("Avatar is required"),
  about: Yup.string().required("About is required"),
});
